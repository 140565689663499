<template>
  <div class="headerTop-links py-3" :class="isScroll && 'sticky'">
    <div class="container" dir="rtl">
      <div class="row">
        <div class="col-12">
          <ul class="list-inline mb-0 d-flex flex-wrap" style="align-items: center;">

            <li v-if="device==='desktop'">
              <SelectLanguage/>
            </li>
            <li class="list-inline-item m-0 home-icon" v-if="isMEMSHAKAgent && currentPage !== 'pending-info'">
              <a href="/external-booking" onclick="return false;" @click="gotoExternalPage">
                <i class="fas fa-th-list"></i>
              </a>
            </li>
            <li class="ml-3 home-icon" v-if="isMEMSHAKAgent && currentPage !== 'booking-cancel'">
              <a href="/cancel-booking" onclick="return false;" @click="gotoCancelPage">
                <i class="fa fa-ban"></i>
              </a>
            </li>
            <li v-if="agencyUser" style="align-self: center;" class="ml-1">
              <b-button @click="agencyLogout" variant="outline-light" size="sm" class="w-100">{{$t('login.log-off') }}</b-button>
            </li>
            <li class="list-inline-item m-0 home-icon" v-if="isNotHomePage && device === 'desktop'">
              <router-link :to="logoURL">
                <i class="fas fa-home"></i>
              </router-link>
            </li>

            <li class="list-inline-item topSocialLink">
              <ul class="list-inline top-social">
                <li class="list-inline-item">
                  <a href="https://www.instagram.com/flyingcarpetincoming" target="_blank"><i class="fab fa-instagram"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.facebook.com/flyingisr" target="_blank"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://t.me/flyingisr" target="_blank"><i class="fab fa-telegram"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://twitter.com/mybilvy2" target="_blank"><i class="fab fa-x-twitter"></i></a>
                </li>
              </ul>
            </li>
            <li class="list-inline-item topFollowUs">
              <a class="" href="#">{{ $t("top-header.follow-us") }} <i class="fas fa-cog"></i></a>
            </li>
            <!-- <li class="list-inline-item topCallUs" v-if="device === 'desktop'">
              <a class="w-100" :href="`tel:${whiteLabel.phoneNumber}`" >{{ whiteLabel.phoneNumber }}: {{ $t('top-header.country-vacation') }} <i class="fas fa-phone-alt"></i></a>
            </li> -->
            <!-- <li class="list-inline-item topCallUs" v-if="device === 'desktop'">
              <a class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" >{{ whiteLabel.vacationPhoneNumber }}: {{ $t('top-header.abroad-vacation') }} <i class="fas fa-phone-alt"></i></a>
            </li> -->
            <!-- <li class="list-inline-item d-flex topCallUs" :class="rtlClassName" style="place-items: inherit;" v-if="device !== 'desktop'">
              <b-dropdown variant="primary" size="sm" ref="dropdown" no-caret>
                <template #button-content>
                  <i class="fas fa-phone-alt"></i> &nbsp;{{ $t("top-header.service-center") }}
                </template>
                <b-dropdown-item :href="`tel:${whiteLabel.phoneNumber}`">
                  <div class="display-flex">
                    <div class="pl-0 pr-2 py-1">
                      <b-button class="w-100" :href="`tel:${whiteLabel.phoneNumber}`" size="sm" variant="primary">{{ whiteLabel.phoneNumber }}: {{ $t('top-header.country-vacation') }}</b-button>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item :href="`tel:${whiteLabel.vacationPhoneNumber}`">
                  <div class="display-flex">
                    <div class="pl-0 pr-2 py-1">
                      <b-button class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" size="sm" variant="primary">{{ whiteLabel.vacationPhoneNumber }}: {{ $t('top-header.abroad-vacation') }}</b-button>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BButton,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue';

export default {
  name: 'TopHeader',
  components: {
    BButton,
    // BDropdown,
    // BDropdownItem,
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
    SelectLanguage: () => import('@/components/atoms/SelectLanguage'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      landingInfo: 'GET_LANDING_INFO',
      destinationList: 'GET_CAMINGO_DESTINATION',
      agencyUser: 'GET_AGENCY_USER',
      odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
      currentPage: 'GET_CURRENT_PAGE',
      marketerId: 'GET_MARKETER_ID',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    dealTypeCode() {
      const { query } = this.$route;
      return query.destination || '';
    },
    destinationName() {
      const { destinationList } = this;
      if (!destinationList) return '';
      return destinationList.find((item) => item.code === this.dealTypeCode).name;
    },
    chainName() {
      return this.isLandingChain ? this.landingInfo.chainName : '';
    },
    utm_source() {
      const { query } = this.$route;
      return query.utm_source || '';
    },
    isNotHomePage() {
      return this.currentPage !== 'home';
    },
    logoURL() {
      const { marketerId } = this;
      return marketerId === '' ? '/' : `/?marketerId=${this.marketerId}`;
    },
  },
  watch: {
    lang() {
      this.changeRtl();
    },
  },
  data() {
    return {
      rtlClassName: '',
      isScroll: true,
    };
  },
  mounted() {
    this.changeRtl();
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    changeRtl() {
      if (this.lang === 'he') {
        this.rtlClassName = 'phone-number-rtl';
      } else {
        this.rtlClassName = '';
      }
    },
    onScroll(event) {
      if (this.$refs.dropdown) this.$refs.dropdown.hide();
      this.isScroll = Number(event.target.scrollTop) > 150;
    },
    async agencyLogout() {
      this.$bvModal.msgBoxConfirm(this.$t('login.log-out-confirm-box-title'), {
        title: this.$t('login.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('login.yes'),
        cancelTitle: this.$t('login.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async (value) => {
          if (value) await this.$store.dispatch('REQUEST_AGENCY_LOGOUT');
          // if (this.odysseyAgentState) { window.location.href = 'https://www.flying-carpet.co.il'; }
        });
    },
    gotoExternalPage() {
      if (window.location.host.includes('localhost')) {
        this.$router.push({ path: '/external-booking' });
      } else {
        const routeData = this.$router.resolve({ path: '/external-booking' });
        window.open(routeData.href, '_blank');
      }
    },
    gotoCancelPage() {
      if (window.location.host.includes('localhost')) {
        this.$router.push({ path: '/cancel-booking' });
      } else {
        const routeData = this.$router.resolve({ path: '/cancel-booking' });
        window.open(routeData.href, '_blank');
      }
    },
  },
};
</script>
<style scoped>
li.home-icon {
  height: 24px;
  border-right: none !important;
}
li.home-icon > a {
  font-size: 1.2rem;
}
li.phone-number-rtl {
  direction: ltr;
}
ul {
  font-family: "Poppins", sans-serif;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}
.landing-part .logo {
  max-width: 230px;
  float: left;
  margin-left: 6rem;
}
.landing-part ul li img {
  max-width: 34px;
  margin-right: 5px;
}
.landing-part ul {
  line-height: 40px;
}
.landing-part .header_rightbox_bottom {
  display: block;
  background-color: #fff;
  padding: 8px 20px 9px 30px;
  position: absolute;
  top: 50px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
  font-size: 1.2em;
}
.landing-part .header_rightbox_bottom a {
  color: #0061ab;
}
.landing-part .header_rightbox_bottom:before {
  content: "";
  position: absolute;
  left: -45px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 45px solid white;
  border-left: 45px solid transparent;
  z-index: 0;
}

.topFollowUs > a:hover, .topCallUs > a:hover {
  color: #adf;
}

@media (max-width: 767px) {
  ul {
    padding: 0;
  }
  .landing-part .logo {
    margin: 20px 0 35px 3rem;
    max-width: 160px;
  }
 .landing-part .header_rightbox_bottom {
    display: initial;
    top: 70px;
  }
}
</style>
<style>

.headerTop-links .btn.dropdown-toggle{
  background: #0061ab;
  border: none;
  padding: 0;
  font-size: inherit;
}
.headerTop-links .btn.dropdown-toggle:hover{
  color: yellow;
}
</style>
